import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/dud_logo.gif';
import Navigation from './Navigation';
import MetaNavigation from './MetaNavigation';

const HeaderContainer = styled.header` 
  padding: 10px;

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    position: fixed;
    padding: 40px 0 40px 40px;
    width: 206px;
    min-height: 100%;
    left: 0;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
  }

  img {
    margin-bottom: 10px;

    @media only screen and (min-width: ${props => props.theme.bp.large}) {
      margin-bottom: 40px;
    }
  }
`;

const MetaNavigationContainer = styled.div`
  display: none;

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    display: block;
  }
`;

const Header = props => {
  return (
    <HeaderContainer>
      <div>
        <Link to="/"><img src={logo} alt="Dengler und Dengler" /></Link>
        <Navigation />  
      </div>
      <MetaNavigationContainer>
        <MetaNavigation />
      </MetaNavigationContainer>
    </HeaderContainer>
  );
};

export default Header;


