import React from 'react';
import styled from 'styled-components';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';

const Image = styled.img`
  margin-bottom: 20px;
  display: block;
`;

const MapContainer = styled.div`
  float: left;
  margin-right: 20px;
`;

const Map = styled.img`
  display: block;
`;

const Woundwann = props => {
  
  const { data } = props;

  return (
    <Page title="Wir">
      <Image src={`${baseUrl}/${data.image.path}`} alt="" />
      <MapContainer>
        <Map src={`${baseUrl}/${data.map.path}`} alt="" />
        <a href={`${baseUrl}/${data.pdf}`} target="_blank" rel="noopener noreferrer">den Plan zum Ausdrucken</a>
      </MapContainer>
      <div dangerouslySetInnerHTML={{ __html: data.text}}></div>
    </Page>
  );
}

export default Woundwann;