import React from 'react';
import Page from '../components/Page';

const Impressum = props => {
  
  const { data } = props;

  return (
    <Page title="Impressum">
      <div dangerouslySetInnerHTML={{ __html: data.text}}></div>
    </Page>
  );
}

export default Impressum;