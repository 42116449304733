import axios from './axios';

const getSettings = () => axios.get(`singletons/get/settings?populate=1`);
const getAssets = () => axios.get('public/getAssets');

const getCollection = (collection, published = false) => {
  if (published) {
    return axios.post(`collections/get/${collection}`, {
      filter: { published: true }
    })
  } 

  return axios.get(`collections/get/${collection}`);
};

const getArtists = () => axios.post(`collections/get/artists`, {
  filter: { published: true },
  sort: { _o: 1 }
});

const getExhibitions = () => axios.post('collections/get/exhibitions', {
  filter: { published: true },
  sort: { year: -1 }
})

const getNews = () => axios.post('collections/get/news', {
  filter: { published: true },
  sort: { _created: -1 }
})

const getSingleton = singleton => axios.get(`singletons/get/${singleton}`);

export default {
  getSettings: getSettings,
  getAssets: getAssets,
  getCollection: getCollection,
  getSingleton: getSingleton,
  getExhibitions: getExhibitions,
  getNews: getNews,
  getArtists: getArtists
}

// const getSchedule = () => axios.get(`collections/get/schedule?populate=1`);
// const getNews = () => axios.get(`collections/get/news?populate=1`);
// const getSingleton = singleton => axios.get(`singletons/get/${singleton}`);
// const getCollection = collection => axios.get(`collections/get/${collection}`);

// export default {
//   getSchedule: getSchedule,
//   getNews: getNews,
//   getSingleton: getSingleton,
//   getCollection: getCollection
// }