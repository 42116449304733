import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import Accordeon from './Accordeon';

const Nav = styled.nav`
  font-size: 1.4rem;
  margin-bottom: 20px;

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    text-align: right;
    margin-bottom: 40px;
  }
`;

const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

const NavItem = styled.li`
  margin-bottom: 2px;
  display: block;
  width: 100%;
`;

const StyledExtLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  background-color: black;
  display: block;
  width: 100%;
  padding: 3px;
  line-height: 1;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;  

  &:hover,
  &.active {
    text-decoration: inherit;
    color: white;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  background-color: black;
  display: block;
  width: 100%;
  padding: 3px;
  line-height: 1;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;

  &:last-child {
    border-bottom: none;
  }

  &:hover,
  &.active {
    text-decoration: inherit;
    color: white;
  }

  ${props => props.nested && css`
    border-bottom: 1px solid black;
    color: black;
    background-color: white;
    padding: 5px 3px;

    &.active {
      font-weight: bold;
      color: black;
    }

    &:hover {
      color: black;
    }
  `}
`;

const Navigation = props => {

  return (
    <Nav>
      <NavList>
        <NavItem>
          <Accordeon nav title="Die Galerie">
            <StyledNavLink nested to="/wir">Über die Galerie</StyledNavLink>
            <StyledNavLink nested to="/woundwann">Lageplan und Öffnungszeiten</StyledNavLink>
          </Accordeon>
        </NavItem>
        <NavItem>
          <Accordeon title="Ausstellungen" nav>
            <StyledNavLink nested to="/ausstellung/aktuell">Aktuell</StyledNavLink>
            <StyledNavLink nested to="/ausstellung/naechste">Nächste</StyledNavLink>
            <StyledNavLink nested to="/archiv">Archiv</StyledNavLink>
          </Accordeon>
        </NavItem>
        <NavItem>
          <StyledNavLink to="/kuenstler">Künstler</StyledNavLink>
        </NavItem>
        <NavItem>
          <StyledNavLink to="/aktuelles">Aktuelles</StyledNavLink>
        </NavItem>
        <NavItem>
          <StyledExtLink href="https://shop.denglerunddengler.de">Shop</StyledExtLink>
        </NavItem>
      </NavList>
    </Nav>
  );
}

export default Navigation;