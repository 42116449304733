import React from 'react';
import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet';
import Footer from './Footer';

const PageContainer = styled.div`
  position: absolute;
  max-width: ${props => props.theme.maxWidth};
  width: 100%;
  padding: 10px;

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    top: 181px;
    max-width: ${props => props.theme.maxWidth};
    width: 100%;
    padding: 0 40px 100px 246px;
  }

  ${props => props.header && css` 
    @media only screen and (min-width: ${props => props.theme.bp.large}) {
      top: 108px;
      top: 92px;
    }
  `}
`;

const Header = styled.div`
  margin-bottom: 35px;
  line-height: 1.3;
  font-size: 1.5em;
`;

const Page = props => {
  const header = props.header ? <Header>{ props.header }</Header> : null;

  return (
    <PageContainer {...props}>
      <Helmet><title>Dengler und Dengler - {props.title}</title></Helmet>
      {header}
      {props.children}

      <Footer />
    </PageContainer>
  );
}

export default Page;