import React, { useState } from 'react';
import styled, { css }  from 'styled-components';

const Acc = styled.div`
  margin-bottom: 0;
`;

const AccTitle = styled.h2`
  background-color: black;
  color: white;
  padding: 10px;
  line-height: 1;
  cursor: pointer;
  font-size: 2.0rem;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 5px;
  
  ${props => props.nav && css`    
    margin: 0;
    font-size: 1.4rem;
    padding: 3px;
  `}
`;

const AccContent = styled.div`
  display: none;

  .open & {
    display: block;
  }
`;

const Accordeon = props => {

  let accordeonState = useState(props.open);
  const container = React.createRef();

  const toggleHandler = (e) => {
    container.current.classList.toggle('open');
    accordeonState[1](!accordeonState[0]);
  };

  return (
    <Acc ref={container} {...props} className={props.open ? 'open' : ''}>
      <AccTitle nav={props.nav} onClick={toggleHandler}>{props.title}</AccTitle>
      <AccContent>{props.children}</AccContent>
    </Acc>
  );
};

export default Accordeon;