import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';

const DetailHeadline = styled.h2`
  margin-bottom: 10px;

  small {
    margin-left: 5px;
  }
`;

const GalleryFigure = styled.figure`
  position: relative;
  vertical-align: top;
  margin: 0 0 10px 0;
  display: flex !important;
  align-items: flex-end;
`;

const GalleryImage = styled.img`
  float: left;
  max-width: 100%;

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    max-width: none;
  }
`;

const Caption = styled.figcaption`
  float: left;
  padding-left: 10px;
  display: none;

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    display: inline-block;
  }
`;

const Stiftungkunstfond = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;

  @media screen and (min-width: 680px) {
    flex-direction: row;
  }
`;

const Img01 = styled.img`
  margin: 0 0 20px 0;
  //max-width: 400px;
  width: 100%;

  @media screen and (min-width: 680px) {
    margin: 0 20px 20px 0;
  }
`;

const Img02 = styled.img`
  max-width: 200px;
  width: 100%;
`;

const VideoContainer = styled.div`
  margin-top: 30px;

  h3 {
    margin: 0 0 10px 0;
    font-weight: normal;
    font-size: 1.5em;
  }
`;

const TextAbove = styled.div`
  margin-bottom: 15px;
`;

const Video = styled.video`
  max-width: 640px;
  width: 100%;
  margin-bottom: 15px;
`;

class Detail extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      slider: null
    }
  }

  componentDidMount() {
    this.setState({
      slider: this.slider
    });
  }

  render() {
    let item = null;

    if (this.props.now) {
      item = this.props.data.current_exhibition;
    } else if (this.props.next) {
      item = this.props.data.next_exhibition;
    } else {
      item = this.props.data.find(item => item.name_slug === this.props.match.params.slug);
    }

    const settings = {
      customPaging: function(i) {
        return <img src={`${baseUrl}/${item.gallery[i].path}`} alt="" title="" />;
      },
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    const vita = item.pdf ? <small><a href={`${baseUrl}/${item.pdf}`} target="_blank" rel="noopener noreferrer">VITA</a></small> : null;
    const web = item.website ? <small><a href={item.website} target="_blank" rel="noopener noreferrer">WEB</a></small> : null;
    const gallery = item.gallery ? (
      <Slider {...settings} ref={slider => (this.slider = slider)}>
        {item.gallery.map(image => (
          <GalleryFigure>
            <GalleryImage src={`${baseUrl}/${image.path}`} alt={this.props.assets[image.meta.asset].description} title={this.props.assets[image.meta.asset].description || null} />
            <Caption>{this.props.assets[image.meta.asset].description}</Caption>
          </GalleryFigure>
        ))}
      </Slider>
    ) : null;

    let result = null;
    let stiftungkunstfond = null;
    let videos = null;

    if (item.video && item.video.length > 0) {
      videos = (
        <div className="videos">
          {item.video.map(video => {
            return (
              <VideoContainer>
                <TextAbove dangerouslySetInnerHTML={{ __html: video.value.text_above }}></TextAbove>
                <Video preload="metadata" src={`${baseUrl}/${video.value.file}`} controls={true}>
                  <source src={`${baseUrl}/${video.value.file}`} type="video/mp4"/>
                </Video>
                <div dangerouslySetInnerHTML={{ __html: video.value.text_below }}></div>
              </VideoContainer>
            );
          })}
        </div>
      );
    }

    if (item.stiftungkunstfond) {
      stiftungkunstfond = (
        <Stiftungkunstfond>
          <a href="https://www.kunstfonds.de/" target="_blank" rel="noopener noreferrer"><Img01 className="stiftungkunstfond--stiftungkunstfond" src="/logo_stiftungkunstfond.png" alt="stiftungkunstfond"/></a>
          <a href="https://www.kunstfonds.de/" target="_blank" rel="noopener noreferrer"><Img02  className="stiftungkunstfond--neustartkultur" src="/logo_neustartkultur.gif" alt="neustartkultur"/></a>
        </Stiftungkunstfond>
      );
    }

    console.log(item);
    let logos = null;

    if (item.logos && item.logos.length > 0) {
      logos = (
        <Stiftungkunstfond>
          {item.logos.map(logo => {
            return (
              <a href={logo.value.link} target="_blank" rel="noopener noreferrer">
                <Img01 src={`${baseUrl}/${logo.value.image.path}`} alt={logo.value.title}/>
              </a>
            );
          })}
        </Stiftungkunstfond>
        // <Stiftungkunstfond>
        //   {item.logos.map(logo => {
        //     return (
        //       <a href="https://www.kunstfonds.de/" target="_blank" rel="noopener noreferrer">
        //         <Img01 className="stiftungkunstfond--stiftungkunstfond" src={`${baseUrl}/${logo.path}`} alt={logo.meta.title}/>
        //       </a>
        //     );
        //   })}
        // </Stiftungkunstfond>
      );
    }


    if (this.props.artist) {
      result = (
        <Page title={item.name}>
          <DetailHeadline>{item.name} {vita} {web}</DetailHeadline>
          { gallery }
          <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
        </Page>
      );
    } else {
      const header = (
        <React.Fragment>
          <strong>{item.name}</strong><br />
          {item.date}
        </React.Fragment>
      );

      result = (
        <Page title={item.name} header={header}>
          { gallery }
          <DetailHeadline>{item.headline}</DetailHeadline>
          <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
          { videos }
          { logos }
        </Page>
      );
    }

    return (
      <React.Fragment>
        {result}
      </React.Fragment>
    );
  }
}

export default Detail;