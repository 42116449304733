import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

const LayoutCSS = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html, body {
    font-size: 62.5%;
  }

  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }

  body {
    margin: 0;
    font-weight: lighter;
    line-height: 1.5;
    text-align: left;
  }

  [tabindex="-1"]:focus {
    outline: none !important;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
  }

  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  dt {
    font-weight: 700;
  }

  dd {
    margin-bottom: .5rem;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  dfn {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bold;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  sub {
    bottom: -.25em;
  }

  sup {
    top: -.5em;
  }

  a {
    color: theme-color("primary");
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }

  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([tabindex]):focus {
    outline: 0;
  }

  pre,
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }

  figure {
    margin: 0 0 1rem;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  a,
  area,
  button,
  [role="button"],
  input:not([type="range"]),
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }

  table {
    border-collapse: collapse;
  }

  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: inherit;
    text-align: left;
    caption-side: bottom;
  }

  th {
    text-align: inherit;
  }

  label {
    display: inline-block;
    margin-bottom: .5rem;
  }

  button {
    border-radius: 0;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }

  progress {
    vertical-align: baseline;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  output {
    display: inline-block;
  }

  summary {
    display: list-item;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none !important;
  }

  body {
    font-family: ${props => props.theme.fontFamily};
    color: black;
    background-color: white;
    font-size: ${props => props.theme.fontSizeDefault};
  }

  .container {
    padding: 0 10px;
    max-width: ${props => props.theme.maxWidth};
  }

  .slick-dots {
    position: static !important;
    text-align: left !important;
    margin-bottom: 10px !important;
  }

  .slick-dots li {
    height: 80px !important;
    width: auto !important;
    margin: 0 10px 10px 0 !important;
  }

  .slick-dots img {
    height: 100%;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  a:hover {
    color: inherit;
  }

  h2 {
    line-height: 1;
  }

  small {
    font-weight: lighter;
    font-size: ${props => props.theme.fontSizeSmall};
  }

  .fade-enter {
    opacity: 0;
    z-index: 1;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity ${props => props.theme.fx.speed} linear;
  }

  .fade-exit {
    opacity: 0;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity ${props => props.theme.fx.speed} linear;
  }
`;

const theme = {
  marginSection: '6.5rem',
  marginSmall: '3rem',
  fontSizeSmaller: '1.0rem',
  fontSizeSmall: '1.2rem',
  fontSizeDefault: '1.4rem',
  fontSizeh1: '5.0rem',
  fontSizeh2: '3.4rem',
  fontSizeh3: '2.4rem',
  maxWidth: '1200px',
  sidebarWidth: '166px',
  borderWidth: '40px',
  greyDark: '#4A2441',
  greyMedium: '#A491A0',
  greyLight: '#F5F5F5',
  orange: '#B0BB00',
  yellow: '#EFF1CC',
  fontFamily: 'Arial, sans-serif',
  transitionSpeed: '0.3s',
  fx: {
    speed: '0.3s'
  },
  flexboxgrid: {
    gridSize: 12,
    gutterWidth: 3,
    outerMargin: 0,
    mediaQuery: 'only screen',
    breakpoints: {
      xs: 0,
      sm: 40, 
      md: 64,
      lg: 75
    }
  },
  bp: {
    large: '900px'
  }
};

const Layout = props => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <LayoutCSS />
      <div className="fadeIn">{props.children}</div>
    </React.Fragment>
  </ThemeProvider>
);

export default Layout;