import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Nav = styled.nav`
  font-size: 1.1rem;
`;

const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

const NavItem = styled.li`
  margin-bottom: 5px;
  display: block;
  width: 100%;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  display: block;
  width: 100%;
  line-height: 1;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;

  &.active {
    font-weight: bold;
  }
`;

const MailLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  display: block;
  width: 100%;
  line-height: 1;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
`;

const MetaNavigation = props => {

  return (
    <Nav>
      <NavList>
        <NavItem>
          <StyledNavLink nested to="/impressum">Impressum</StyledNavLink>
        </NavItem>
        <NavItem>
          <MailLink href="mailto:info@denglerunddengler.de">Kontakt</MailLink>
          {/* <StyledNavLink nested to="/kontakt">Kontakt</StyledNavLink> */}
        </NavItem>
        <NavItem>
          <StyledNavLink nested to="/datenschutz">Datenschutz</StyledNavLink>
        </NavItem>
      </NavList>
    </Nav>
  );
}

export default MetaNavigation;