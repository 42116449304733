import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-styled-flexboxgrid';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';

const ArtistImage = styled.img`
  width: 100%;
`;

const StyledCol = styled(Col)`
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Kuenstler = props => {

  return (
    <Page title="Künstler">
      <Row>
        {props.data.map(artist => (
          <StyledCol key={artist.name} xs={12} sm={6} md={4}>
            <StyledLink to={`/kuenstler/${artist.name_slug}`}>
              {artist.image ? <ArtistImage src={`${baseUrl}/${artist.image.path}`} alt="" /> :  null}
              {/* <ArtistImage src="https://via.placeholder.com/800x600" alt="" /> */}
              {artist.name}
            </StyledLink>
          </StyledCol>
        ))}
      </Row>
    </Page>
  );
}

export default Kuenstler;