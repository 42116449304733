import React from 'react';
import styled from 'styled-components';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';

const Image = styled.img`
  margin-bottom: 20px;
`;

const Wir = props => {
  
  const { data } = props;

  return (
    <Page title="Wir">
      <Image src={`${baseUrl}/${data.image.path}`} alt="" />
      <div dangerouslySetInnerHTML={{ __html: data.text}}></div>
    </Page>
  );
}

export default Wir;