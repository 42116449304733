import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import Accordeon from '../components/Accordeon';

const ExhibitionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ExhibitionItem = styled.li`
  
`;

const ExhibitionLink = styled(Link)`
  text-decoration: none;
  display: block;
  padding: 10px;
  border-bottom: 1px solid black;

  &:hover {
    text-decoration: inherit;
  }
`;
 
const Archiv = props => {
  
  const exhibitionsByYear = {};
  
  props.data.forEach(exhibition => {
    if (exhibitionsByYear[exhibition.year]) {
      exhibitionsByYear[exhibition.year].push(exhibition);
    } else {
      exhibitionsByYear[exhibition.year] = [exhibition];
    }
  });

  const years = [];

  for (let year in exhibitionsByYear) {
    let yearArr = [];
    for (let i = 0; i < exhibitionsByYear[year].length; i += 1) {
      yearArr.push(exhibitionsByYear[year][i]);
    }
    years.push(yearArr);
  }

  return (
    <Page title="Archiv">
      {years.reverse().map(year => {
        return (
          <Accordeon title={year[0].year}>
            <ExhibitionList>
              {year.map(exhibition => (
                <ExhibitionItem>
                  <ExhibitionLink to={`/ausstellung/${exhibition.name_slug}`}>
                    {exhibition.date} / {exhibition.artist ? `${exhibition.artist} /`: null} {exhibition.name}
                  </ExhibitionLink>
                </ExhibitionItem>
              ))}
            </ExhibitionList>
          </Accordeon>
        )
      })}
    </Page>
  );
}

export default Archiv;
