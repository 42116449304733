import React from 'react';
import styled from 'styled-components';
import MetaNavigation from './MetaNavigation';

const FooterContainer = styled.header` 
  display: block;
  margin-top: 100px;

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    display: none;
  }
`;

const Footer = props => {
  return (
    <FooterContainer>
      <MetaNavigation />
    </FooterContainer>
  );
};

export default Footer;


