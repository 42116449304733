import React from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Layout from './components/Layout';
import Header from './components/Header';
import Home from './pages/Home';
import cockpit from './utils/cockpit';
import Kuenstler from './pages/Kuenstler';
import Archiv from './pages/Archiv';
import Detail from './pages/Detail';
import Wir from './pages/Wir';
import Woundwann from './pages/Woundwann';
import Aktuelles from './pages/Aktuelles';
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';

class App extends React.Component {

  state = {};

  componentDidMount() {
    cockpit.getSettings().then(res => {
      this.setState({
        settings: res.data
      });
    });

    cockpit.getAssets().then(res => {
      let assetsObject = {};

      for (let asset in res.data) {
        assetsObject[res.data[asset]._id] = res.data[asset];
      };

      this.setState({
        assets: assetsObject
      });
    });

    // cockpit.getCollection('artists', true).then(res => {
    //   this.setState({
    //     artists: res.data.entries
    //   })
    // });

    cockpit.getArtists().then(res => {
      this.setState({
        artists: res.data.entries
      })
    });

    // cockpit.getCollection('news', true).then(res => {
    //   this.setState({
    //     news: res.data.entries
    //   })
    // });

    cockpit.getNews().then(res => {
      this.setState({
        news: res.data.entries
      })
    });

    cockpit.getExhibitions().then(res => {
      this.setState({
        exhibitions: res.data.entries
      })
    });

    cockpit.getSingleton('wir').then( res => {
      this.setState({
        wir: res.data
      })
    });

    cockpit.getSingleton('woundwann').then( res => {
      this.setState({
        woundwann: res.data
      })
    });

    cockpit.getSingleton('impressum').then( res => {
      console.log(res.data);
      this.setState({
        impressum: res.data
      })
    });

    cockpit.getSingleton('datenschutz').then( res => {
      this.setState({
        datenschutz: res.data
      })
    });
  }

  render() {
    const { settings, assets, artists, exhibitions, wir, woundwann, news, impressum, datenschutz } = this.state;

    let result = null;

    if (settings && assets && artists && exhibitions && wir && woundwann && news && impressum && datenschutz) {
      result = (
        <React.Fragment>
          <Header />
          <main>
            <TransitionGroup>
              <CSSTransition 
                key={this.props.location.key} 
                classNames="fade" 
                timeout={{enter: 300, exit: 300}}
                mountOnEnter={true}
                unmountOnExit={true}>
                  
                <Switch>
                  <Route path="/" exact render={ props => <Detail {...props} data={settings} assets={assets} now /> } />
                  <Route path="/wir" render={ props => <Wir {...props} data={wir} /> } />
                  <Route path="/woundwann" render={ props => <Woundwann {...props} data={woundwann} /> } />
                  <Route path="/impressum" render={ props => <Impressum {...props} data={impressum} /> } />
                  <Route path="/datenschutz" render={ props => <Datenschutz {...props} data={datenschutz} /> } />
                  <Route path="/kuenstler/:slug" render={props => <Detail {...props} data={artists} assets={assets} artist /> } />
                  <Route path="/kuenstler" render={props => <Kuenstler {...props} data={artists} /> } />
                  <Route path="/ausstellung/aktuell" render={props => <Detail {...props} data={settings} assets={assets} now /> } />
                  <Route path="/ausstellung/naechste" render={props => <Detail {...props} data={settings} assets={assets} next /> } />
                  <Route path="/ausstellung/:slug" render={props => <Detail {...props} data={exhibitions} assets={assets} /> } />
                  <Route path="/archiv" render={props => <Archiv {...props} data={exhibitions} /> } />
                  <Route path="/aktuelles" render={props => <Aktuelles {...props} data={news} assets={assets} /> } />
                </Switch>

              </CSSTransition>
            </TransitionGroup>
          </main>
        </React.Fragment>
      );
    }

    return (
      <Layout>
        { result }
      </Layout>
    );
  }
}

export default withRouter(App);
