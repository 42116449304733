import React from 'react';
import styled from 'styled-components';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';

const NewsArticle = styled.article`
  border-bottom: 1px solid black;
  margin-bottom: 20px;
  padding-bottom: 20px;

  &:last-child {
    border-bottom: none;
  }
`;

const NewsHeadline = styled.h2`
  margin-bottom: 10px;
`;

// const NewsFigure = styled.figure`
//   display: flex;
//   align-items: flex-end;
// `;

const NewsImage = styled.img`
  margin-bottom: 10px;

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    max-width: none;
  }
`;

// const Caption = styled.figcaption``;

const Aktuelles = props => {
  return (
    <Page title="Aktuelles">
      {props.data.map(news => (
        <NewsArticle>
          <NewsHeadline>{news.title}</NewsHeadline>
          <NewsImage src={`${baseUrl}/${news.image.path}`} alt="" />
          {/* <NewsFigure>
            {news.image ? (
              <React.Fragment>
                <NewsImage src={`${baseUrl}/${news.image.path}`} alt={props.assets[news.image.meta.asset].description} />
                <Caption>{props.assets[news.image.meta.asset].description}</Caption>
              </React.Fragment>
            ) : null}
          </NewsFigure> */}
          
          <div dangerouslySetInnerHTML={{ __html: news.description}}></div>
        </NewsArticle>
      ))}
    </Page>
  );
}

export default Aktuelles;
